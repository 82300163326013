<!--  -->
<template>
    <div class='page-game'>
        <div class="game-body">
            <router-view 
            :appStyle.sync="appStyle"
            />
        </div>
    </div>
</template>

<script>
    import game from '@/API/page/game'
    export default {
        name: '',
        props: {
            appStyle: Object
        },
        data() {
            return {
                api: new game(),
                appData: new this.appDataClass({key: 'game'}),
                path: this.$route.path
            };
        },
        methods: {

        },
        created() {
        },
        components: {
        },
        computed: {
            needGotoPage(){
                let {path} = this
                let routeList = this._routeList
                let list = {
                    '/game': routeList['game-list']
                }
                let cur_path = null
                for(let i in list){
                    let r_path = list[i].path
                    if(i == path){
                        cur_path = list[i]
                        break
                    }
                }
                return cur_path
            }
        },
        watch: {
            needGotoPage: {
                handler(val){
                    if(!val) return
                    let {name, params} = val
                    let type = 'replace'
                    this._routeHand.goto({name, params, type})
                },
                deep: true,
                immediate: true
            },
            $route: {
                handler(){
                    this.path = this.$route.path
                },
                deep: true,
                immediate: true
            }
        },
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .page-game{
        // height: 100%;
        // width: 100%;
        // .game-body{
        //     height: 100%;
        //     width: 100%;
        // }
        .game-select-box{
            min-height: 192px;
            width: 303px;
            padding: 20px 0px;
            .game-select-title{
                text-align: center;
                margin-bottom: 20px;
            }
            .select-item{
                width: 156px;
                height: 40px;
                border: 1px solid #D2D2D2;
                border-radius: 8px;
                margin: 0px auto;
                display: flex;
                justify-content: center;
                align-items: center;
                .select-icon{
                    width: 24px;
                    height: auto;
                    margin-right: 7px;
                }
            }
            .select-item+ .select-item{
                margin-top: 16px;
            }
        }
    }
</style>